/* eslint-disable no-loop-func */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Card, CardBody } from '@progress/kendo-react-layout';
import ClickOutside from 'app/widgets/ClickOutside';
import { Button } from '@progress/kendo-react-buttons';
import SearchIcon from 'assets/images/ic_search.svg';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import { ic_filter as FilterIcon } from 'app/widgets/SVGs';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { getDefaultThumbnail } from '../../Equipment/utils';
import { ic_close_small as NormalCloseIcon } from 'app/widgets/SVGs';
import { CacheContext } from 'app/components/ReactContexts/cacheContext';

export function ResourceSubcategoriesPage(props: any) {
  const [categoriesList, setCategoriesList] = React.useState<Array<any>>();
  const [modelsList, setModelsList] = React.useState<Array<any>>();
  const [searchValue, setSearchValue] = React.useState('');
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [parentsList, setParentsList] = React.useState<Array<any>>([]);
  const [searchValues, setSearchValues] = React.useState<Array<any>>([]);
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = React.useState(false);
  const [modelFilterItem, setModelFilterItem] = React.useState<any>();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const [parentName, setParentName] = React.useState<any>();
  const [showNotFound, setShowNotFound] = React.useState(false);
  const [allCategoriesList, setAllCategoriesList] =
    React.useState<Array<any>>();
  const [allModelsList, setAllModelsList] = React.useState<Array<any>>();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: parentId } = useParams();
  const modelFilter = (location.state as { modelFilter: string })?.modelFilter;
  const lastCommonParent = (location.state as { lastCommonParent: string })
    ?.lastCommonParent;
  const { getApiModels } = React.useContext(CacheContext);

  function getChildrenCount(categoryId) {
    let count = 0;
    if (modelsList && modelsList?.some(m => m.categoryId == categoryId)) {
      return modelsList.filter(m => m.categoryId == categoryId).length;
    } else {
      let childrenCategories = allCategoriesList?.filter(
        c => c.parentId == categoryId,
      );
      if (childrenCategories) {
        for (let i = 0; i < childrenCategories?.length; i++) {
          count += getChildrenCount(childrenCategories[i].id);
        }
      }
    }
    return count;
  }

  const findParents = () => {
    const parents: any[] = [];
    let currentParentId = parentId;
    while (currentParentId !== undefined && currentParentId != null) {
      const currentParent = allCategoriesList?.filter(
        // eslint-disable-next-line no-loop-func
        c => c.id == currentParentId,
      )[0];
      currentParentId = currentParent?.parentId;
      var parentToAdd = {
        name: currentParent?.name,
        id: currentParent?.id,
      };
      if (currentParent) {
        parents.unshift(parentToAdd);
      }
    }
    if (modelFilter != null && modelFilterItem.type != 'attachment') {
      setParentsList(parents.filter(p => p.id != 39));
    } else if (
      modelFilter != null &&
      modelFilterItem.type == 'attachment' &&
      lastCommonParent != null &&
      lastCommonParent != undefined
    ) {
      const indexToStartFrom = parents.findIndex(
        p => p.id === lastCommonParent,
      );
      if (indexToStartFrom !== -1) {
        const filteredParents = parents.slice(indexToStartFrom + 1);
        setParentsList(filteredParents);
      }
    } else {
      setParentsList(parents);
    }
  };
  const getSearchValues = categoryId => {
    if (categoryId == parentId) {
      setSearchValues([]);
    }

    let children = modelsList?.filter(m => m.categoryId == categoryId);

    if (children && children.length > 0) {
      setSearchValues(prevSearchValues => prevSearchValues.concat(children));
    } else {
      let childrenCategories = categoriesList?.filter(
        m => m.parentId == categoryId,
      );
      if (childrenCategories && childrenCategories.length > 0) {
        for (let i = 0; i < childrenCategories.length; i++) {
          getSearchValues(childrenCategories[i].id);
        }
      }
    }
  };
  const getApiModelsRef = React.useRef(0);

  React.useEffect(() => {
    (async () => {
      setAllCategoriesList(undefined);
      setAllModelsList(undefined);
      setCategoriesList(undefined);
      setModelsList(undefined);
      const newNumber = getApiModelsRef.current + 1;
      getApiModelsRef.current = newNumber;
      const apiModel = await getApiModels();
      if (getApiModelsRef.current !== newNumber) {
        return;
      }
      if (!apiModel.categories.some(c => c.id == parentId)) {
        setShowNotFound(true);
      }
      const sortedModelsData = [...apiModel.models].sort((a, b) =>
        a.model.localeCompare(b.model),
      );
      const sortedCategoriesData = [...apiModel.categories].sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      setAllCategoriesList(sortedCategoriesData);
      setAllModelsList(sortedModelsData);
      setCategoriesList(sortedCategoriesData);
      setModelsList(sortedModelsData);
    })();
  }, [i18n.language]);

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    if (allModelsList && allCategoriesList) {
      isFiltered
        ? setModelsList(
            allModelsList.filter(m => m.discontinuedDate == undefined),
          )
        : setModelsList(allModelsList);
      setCategoriesList(allCategoriesList);
      if (!parentName) {
        setParentName(allCategoriesList.filter(c => c.id == parentId)[0]?.name);
      }
      if (modelFilter != null) {
        var filteredItem = allModelsList.filter(m => m.model == modelFilter)[0];

        setModelFilterItem(
          allModelsList.filter(m => m.model == modelFilter)[0],
        );
        var models =
          filteredItem.type != 'attachment'
            ? allModelsList.filter(m =>
                filteredItem.compatibleAttachments.includes(m.model),
              )
            : allModelsList.filter(
                m =>
                  m.compatibleAttachments &&
                  m.compatibleAttachments.includes(modelFilter),
              );

        if (allCategoriesList && parentId != undefined) {
          const parent = allCategoriesList.filter(c => c.id == parentId)[0];
          let childrenCategories = [parent];

          if (models) {
            for (let i = 0; i < models.length; i++) {
              let currentParentId = models[i].categoryId;

              while (
                currentParentId !== parentId &&
                currentParentId !== undefined
              ) {
                const currentParent = allCategoriesList.filter(
                  c => c.id === currentParentId,
                )[0];

                if (
                  childrenCategories !== undefined &&
                  // eslint-disable-next-line no-loop-func
                  !childrenCategories.some(c => c.id === currentParentId)
                ) {
                  childrenCategories.push(currentParent);
                } else {
                  break;
                }

                currentParentId = currentParent?.parentId;
              }
            }
            childrenCategories.sort((a, b) => {
              if (a.index !== b.index) {
                return a.index - b.index;
              }
              return a.name.localeCompare(b.name);
            });
            setCategoriesList([...childrenCategories]);
          }
        }
      }
    }
  }, [isFiltered, allCategoriesList]);

  React.useEffect(() => {
    if (categoriesList) {
      setParentName(categoriesList.filter(c => c.id == parentId)[0]?.name);
    }
    if (
      categoriesList &&
      modelFilter == null &&
      (parentName != undefined ||
        categoriesList.filter(c => c.id == parentId)[0]?.name != undefined)
    ) {
      findParents();
    } else if (
      modelsList &&
      modelFilter != null &&
      modelFilterItem &&
      modelFilterItem.type != 'attachment' &&
      modelFilterItem.compatibleAttachments != undefined &&
      parentName != undefined
    ) {
      var newList = modelsList?.filter(m =>
        modelFilterItem.compatibleAttachments.includes(m.model),
      );
      setModelsList(newList);
      findParents();
    } else if (
      modelsList &&
      modelFilter != null &&
      modelFilterItem &&
      modelFilterItem.type == 'attachment' &&
      parentName != undefined
    ) {
      var newModelsList = modelsList.filter(
        m =>
          m.compatibleAttachments &&
          m.compatibleAttachments.includes(modelFilter),
      );
      setModelsList(newModelsList);
      findParents();
    }
  }, [parentId, categoriesList, allCategoriesList]);

  React.useEffect(() => {
    setSearchValues([]);
    if (categoriesList && parentId != undefined) {
      getSearchValues(parentId);
    }
  }, [searchValue, parentId, modelsList]);

  const handleFilterChange = event => {
    setIsFiltered(event.target.value);
    setIsFilterExpanded(false);
  };

  var parentIsLastCategory =
    categoriesList?.filter(c => c.parentId == parentId).length == 0;

  if (!modelsList || !categoriesList) {
    return (
      <div className="loading-full-page">
        <div className="loading">
          <div className="loading_text">{t('loading...')}</div>
          <div className="loading_icon">
            <div className="css-icon"></div>
          </div>
        </div>
      </div>
    );
  } else if (showNotFound) {
    return <NotFoundPage />;
  }

  return (
    <div className="relative">
      <span
        className="back-button back-button-on-layout fixed-on-desktop"
        onClick={() => {
          navigate(-1);
        }}
      >
        <BackIcon />
      </span>
      <div className="title-with-search and-filter">
        <div className="title-box">
          <h1>{parentName}</h1>
        </div>
        <div className="search-box">
          <ClickOutside
            className="search-with-options"
            onClickOutside={() => {
              setIsSearchExpanded(false);
            }}
          >
            <div className="site-search open">
              <span className="search-icon">
                <img alt="Search" src={SearchIcon}></img>
              </span>
              <div className="search-input">
                <Input
                  id="search"
                  name="search"
                  value={searchValue}
                  placeholder={t('search_for', { s: parentName })}
                  onChange={newValue => {
                    setSearchValue(newValue.value);
                  }}
                  onClick={() => setIsSearchExpanded(!isSearchExpanded)}
                />
                {searchValue && (
                  <span
                    className="search-icon-close"
                    onClick={() => {
                      setSearchValue('');
                    }}
                  >
                    <BlackCloseIcon />
                  </span>
                )}
              </div>
            </div>
            <div className="search-options">
              {isSearchExpanded && (
                <Card>
                  <CardBody>
                    {searchValues?.some(
                      c =>
                        c.model
                          .toLowerCase()
                          .includes(searchValue.toLocaleLowerCase()) ||
                        (c.modelDescription &&
                          c.modelDescription
                            .toLowerCase()
                            .includes(searchValue.toLocaleLowerCase())),
                    ) ? (
                      _.map(
                        searchValues.filter(
                          c =>
                            c.model
                              .toLowerCase()
                              .includes(searchValue.toLocaleLowerCase()) ||
                            (c.modelDescription &&
                              c.modelDescription
                                .toLowerCase()
                                .includes(searchValue.toLocaleLowerCase())),
                        ),
                        model => {
                          return (
                            <div
                              key={model.model}
                              onClick={() => navigate('/models/' + model.model)}
                            >
                              {model.model}
                            </div>
                          );
                        },
                      )
                    ) : (
                      <div className="not-found-message">
                        {t('no_result_for', { s: searchValue })}
                      </div>
                    )}
                  </CardBody>
                </Card>
              )}
            </div>
          </ClickOutside>
        </div>
        <div className="filter-box">
          <ClickOutside
            className="filter"
            onClickOutside={() => {
              setIsFilterExpanded(false);
            }}
          >
            <Button
              className="button-filter"
              onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            >
              <FilterIcon />
              {t('equipment_tree_btn_filter')}
            </Button>

            <div className="filter-options">
              {isFilterExpanded && (
                <Card>
                  <CardBody>
                    <div>{t('equipment_tree_filter_discontinued')}</div>
                    <Switch
                      checked={isFiltered}
                      onChange={handleFilterChange}
                      onLabel=""
                      offLabel=""
                    />
                  </CardBody>
                </Card>
              )}
            </div>
          </ClickOutside>
        </div>
      </div>

      <div>
        {modelFilter != null && (
          <Button className="button-tag-close without-close">
            {t('model_compatible_with', { s: modelFilter })}
          </Button>
        )}
        {parentsList &&
          _.map(parentsList, parentItem => {
            return (
              <span key={parentItem.id}>
                <Button
                  className="button-tag-close"
                  onClick={() => {
                    if (parentsList.length == 1) {
                      if (
                        modelFilter != null &&
                        modelFilterItem.type == 'attachment'
                      ) {
                        lastCommonParent != null &&
                        lastCommonParent != undefined
                          ? navigate(`/resources/${lastCommonParent}`, {
                              state: {
                                modelFilter: modelFilter,
                                lastCommonParent: lastCommonParent,
                              },
                            })
                          : navigate(`/resources`, {
                              state: {
                                modelFilter: modelFilter,
                                lastCommonParent: lastCommonParent,
                              },
                            });
                      } else if (
                        modelFilter != null &&
                        modelFilterItem.type != 'attachment'
                      ) {
                        navigate('/resources/39', {
                          state: {
                            modelFilter: modelFilter,
                          },
                        });
                      } else {
                        navigate('/resources');
                      }
                    } else {
                      var updatedList = parentsList.filter(
                        p => p.id != parentItem.id,
                      );
                      var lastElement = updatedList.slice(-1)[0];
                      setParentsList(prevList =>
                        prevList.filter(p => p.id !== parentItem.id),
                      );
                      if (modelFilter != null) {
                        if (modelFilterItem.type == 'attachment') {
                          navigate(`/resources/${lastElement.id}`, {
                            state: {
                              modelFilter: modelFilter,
                              lastCommonParent: lastCommonParent,
                            },
                          });
                        } else {
                          navigate('/resources/' + lastElement.id, {
                            state: {
                              modelFilter: modelFilter,
                            },
                          });
                        }
                      } else {
                        navigate('/resources/' + lastElement.id);
                      }
                    }
                  }}
                >
                  {parentItem.name}
                  <NormalCloseIcon />
                </Button>
              </span>
            );
          })}
        {isFiltered && (
          <Button
            className="button-tag-close"
            onClick={() => setIsFiltered(false)}
          >
            {t('equipment_tree_filter_discontinued')}
            <NormalCloseIcon />
          </Button>
        )}
      </div>
      <div>
        <div className="add-equipment-boxes resources-style">
          {!parentIsLastCategory
            ? _.map(
                categoriesList?.filter(c => c.parentId == parentId),
                resource => {
                  return (
                    <>
                      {getChildrenCount(resource.id) > 0 && (
                        <span key={resource.id}>
                          <div className="equipment-content-actions section">
                            <div
                              className="add-equipment-item"
                              onClick={() => {
                                if (modelFilter == null) {
                                  navigate('/resources/' + resource.id);
                                } else {
                                  navigate('/resources/' + resource.id, {
                                    state: {
                                      lastCommonParent: lastCommonParent,
                                      modelFilter: modelFilter,
                                    },
                                  });
                                }
                              }}
                            >
                              <div className="product-length">
                                <span>{getChildrenCount(resource.id)}</span>
                              </div>

                              <div className="add-equipment-box-img">
                                <img
                                  src={
                                    resource.iconUrl ||
                                    getDefaultThumbnail(
                                      modelFilter != null
                                        ? 'attachment'
                                        : parentsList[0]?.name,
                                      modelFilter != null
                                        ? 'attachment'
                                        : parentsList[0]?.name,
                                    )
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = getDefaultThumbnail(
                                      modelFilter != null
                                        ? 'attachment'
                                        : parentsList[0]?.name,
                                      modelFilter != null
                                        ? 'attachment'
                                        : parentsList[0]?.name,
                                    );
                                  }}
                                  alt=""
                                ></img>
                              </div>

                              <h3>{resource.name}</h3>
                              <img
                                className="arrow-links_icon arrow-links_icon-right"
                                src={ChevronRightIcon}
                              ></img>
                            </div>
                          </div>
                        </span>
                      )}
                    </>
                  );
                },
              )
            : _.map(
                modelsList?.filter(m => m.categoryId == parentId),
                model => {
                  return (
                    <div
                      className="equipment-content-actions section"
                      key={model.model}
                    >
                      <div
                        className="add-equipment-item"
                        onClick={() => navigate('/models/' + model.model)}
                      >
                        <div className="add-equipment-box-img">
                          <img
                            src={
                              model.modelFullUrl ||
                              model.modelIconUrl ||
                              getDefaultThumbnail(model.type)
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = getDefaultThumbnail(
                                model.type,
                              );
                            }}
                            alt=""
                          />
                        </div>
                        <div className="item-grow">
                          <h3>{model.model}</h3>
                          <span>{model.modelDescription}</span>
                        </div>
                        <img
                          className="arrow-links_icon arrow-links_icon-right"
                          src={ChevronRightIcon}
                        ></img>
                      </div>
                    </div>
                  );
                },
              )}
        </div>
      </div>
    </div>
  );
}
