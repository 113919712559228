import * as React from 'react';
import { KubotaMenu } from 'app/components/Menu';
import { KubotaAppBar } from 'app/components/AppBar';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import _ from 'lodash';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';

interface ISubmenuAndContent {
  submenu: JSX.Element;
  children: JSX.Element;
}

export function SubmenuAndContent(props: ISubmenuAndContent) {
  const { classNames } = React.useContext(LayoutContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);

  const classString = _.uniq(
    _.map(_.orderBy(classNames, ['index'], ['asc']), cls => cls.class),
  ).join(' ');
  return (
    <>
      <div
        className={`layout layout-submenu-and-content ${classString} ${
          equipmentListData.isDrawing && 'layout-geofences-drawing'
        }`}
      >
        <div className="layout_menu">
          <KubotaMenu />
        </div>
        <div className="layout_submenu">{props.submenu}</div>
        <div className={`layout_container`}>
          <div className="site-header">
            <KubotaAppBar />
          </div>
          <div className="site-content">{props.children}</div>
        </div>
      </div>
    </>
  );
}
