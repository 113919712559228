import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { ajax } from 'ajax/ajax';
import { PasswordDecorators } from 'app/widgets/PasswordDecorators';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HidePassIcon from '../../../assets/images/ic_field_hidepass.svg';
import ShowPassIcon from '../../../assets/images/ic_field_showpass.svg';
interface IResetPasswordProps {
  close: () => void;
}

export function ResetPasswordPage(props: IResetPasswordProps) {
  const [oldPassword, setOldPassword] = React.useState('');
  const [oldPasswordError, setOldPasswordError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [repeatPasswordError, setRepeatPasswordError] = React.useState('');
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    React.useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = React.useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] =
    React.useState(false);

  const { t } = useTranslation();

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const navigate = useNavigate();

  const validateOldPassword = () => {
    if (!oldPassword) {
      setOldPasswordError(t('field_is_required'));
      return false;
    }
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError(t('field_is_required'));
      return false;
    }
    if (
      password.length < 8 ||
      !password.match(/^.*[a-zA-Z]+.*$/) ||
      !password.match(/^.*[0-9]+.*$/) ||
      !password.match(/[^\w\s]/g)
    ) {
      setPasswordError(t('password_insecure'));
      return false;
    }
    return true;
  };

  const validateConfirmPassword = () => {
    if (!repeatPassword) {
      setRepeatPasswordError(t('field_is_required'));
      return false;
    }
    if (password !== repeatPassword) {
      setRepeatPasswordError(t('passwords_do_not_match'));
      return false;
    }
    return true;
  };

  const validateForm = () => {
    let isValid = true;
    isValid = validateOldPassword() && isValid;
    isValid = validatePassword() && isValid;
    isValid = validateConfirmPassword() && isValid;
    return isValid;
  };

  const changePasswordButtonClicked = () => {
    if (!validateForm()) {
      return;
    }
    const params = new URLSearchParams();
    params.append('current_password', oldPassword);
    params.append('password', password);
    setIsSubmiting(true);
    ajax
      .patch(`${ajax.appBaseUrl}/oauth/user`, params)
      .then(result => {
        navigate('/Login');
      })
      .catch(e => {
        if (e.response.data.errorCode === 'error.email_exists') {
          return;
        }

        if (e.response.data.errorCode === 'NotAValidPhoneNumber') {
          return;
        }

        if (e.response.data.errorCode === 'InvalidCurrentPassword') {
          setOldPasswordError(t('invalid_current_password'));
          return;
        }

        setOldPasswordError(
          e.response.data.errorMessage || e.response.data.error,
        );
        //throw e;
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="modal-container relative">
            <span className="back-button absolute" onClick={props.close}>
              <svg
                id="ic_nav_back"
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <rect id="Box" width="48" height="48" fill="none" />
                <path
                  id="ic_nav_up"
                  d="M16.465,8.482H1.483"
                  transform="translate(15.518 15.518)"
                  fill="none"
                  stroke="#1a1a1a"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_470"
                  data-name="Path 470"
                  d="M23.676,16.018,16.2,23.748l7.477,7.73"
                  transform="translate(0.324 0.252)"
                  fill="none"
                  stroke="#1a1a1a"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </svg>
            </span>
            <h1>{t('change_password_preference_title')}</h1>

            <div className="form-caption">
              <span>{t('create_account_header')}</span>
              <div>
                <b>{t('required_fields_warning')}</b>
              </div>
            </div>

            <div className={`form-group ${oldPasswordError && 'error'}`}>
              <div className="form-content">
                <div className="form-label">
                  <span>{t('current_password')} *</span>
                </div>
                <div className="form-control form-control-password">
                  <Input
                    id="oldPassword"
                    name="oldPassword"
                    tabIndex={1}
                    type={isCurrentPasswordVisible ? 'text' : 'password'}
                    placeholder={t('enter_current_password')}
                    value={oldPassword}
                    onChange={event => {
                      setOldPassword(event.target.value as string);
                      setOldPasswordError('');
                    }}
                    disabled={isSubmiting}
                  />
                  <span
                    className={`show-password ${
                      isCurrentPasswordVisible ? 'pass-visible' : ''
                    }`}
                    onClick={() =>
                      setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                    }
                  >
                    <img
                      alt="Hide Password"
                      src={HidePassIcon}
                      className="hide-pass-icon"
                    ></img>
                    <img
                      alt="Show Password"
                      src={ShowPassIcon}
                      className="show-pass-icon"
                    ></img>
                  </span>
                </div>
              </div>
              <div className="form-error">
                {oldPasswordError && (
                  <Error id={'oldPasswordError'}>{oldPasswordError}</Error>
                )}
              </div>
            </div>

            <div className={`form-group ${passwordError && 'error'}`}>
              <div className="form-content">
                <div className="form-label">
                  <span>{t('new_password')} *</span>
                </div>
                <div className="form-control form-control-password">
                  <Input
                    id="password"
                    name="password"
                    tabIndex={2}
                    type={isNewPasswordVisible ? 'text' : 'password'}
                    placeholder={t('enter_new_password')}
                    value={password}
                    onChange={event => {
                      setPassword(event.target.value as string);
                      setPasswordError('');
                    }}
                    disabled={isSubmiting}
                  />
                  <span
                    className={`show-password ${
                      isNewPasswordVisible ? 'pass-visible' : ''
                    }`}
                    onClick={() =>
                      setIsNewPasswordVisible(!isNewPasswordVisible)
                    }
                  >
                    <img
                      alt="Hide Password"
                      src={HidePassIcon}
                      className="hide-pass-icon"
                    ></img>
                    <img
                      alt="Show Password"
                      src={ShowPassIcon}
                      className="show-pass-icon"
                    ></img>
                  </span>
                </div>
              </div>
              <div className="form-error">
                {passwordError && (
                  <Error id={'passwordError'}>{passwordError}</Error>
                )}
              </div>
            </div>

            <div className="password-decorators">
              <PasswordDecorators password={password} />
            </div>

            <div className={`form-group ${repeatPasswordError && 'error'}`}>
              <div className="form-content">
                <div className="form-label">
                  <span>{t('repeat_password')} *</span>
                </div>
                <div className="form-control form-control-password">
                  <Input
                    id="repeatPassword"
                    name="repeatPassword"
                    tabIndex={3}
                    type={isRepeatPasswordVisible ? 'text' : 'password'}
                    placeholder={t('repeat_password_hint')}
                    value={repeatPassword}
                    onChange={event => {
                      setRepeatPassword(event.target.value as string);
                      setRepeatPasswordError('');
                    }}
                    disabled={isSubmiting}
                  />
                  <span
                    className={`show-password ${
                      isRepeatPasswordVisible ? 'pass-visible' : ''
                    }`}
                    onClick={() =>
                      setIsRepeatPasswordVisible(!isRepeatPasswordVisible)
                    }
                  >
                    <img
                      alt="Hide Password"
                      src={HidePassIcon}
                      className="hide-pass-icon"
                    ></img>
                    <img
                      alt="Show Password"
                      src={ShowPassIcon}
                      className="show-pass-icon"
                    ></img>
                  </span>
                </div>
              </div>
              <div className="form-error">
                {repeatPasswordError && (
                  <Error id={'repeatPasswordError'}>
                    {repeatPasswordError}
                  </Error>
                )}
              </div>
            </div>

            <Button
              tabIndex={4}
              themeColor="primary"
              onClick={changePasswordButtonClicked}
              disabled={isSubmiting}
            >
              {isSubmiting ? (
                <div className="loading_icon loading_icon-btn">
                  <div className="css-icon"></div>
                </div>
              ) : (
                t('change_password')
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
