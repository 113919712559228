import * as React from 'react';
import { MarkerF } from '@react-google-maps/api';
import LocationImage from 'assets/images/map_mylocation.svg';
import MapLocalize from 'assets/images/map_localize.svg';
import { DealerListContext } from '../ReactContexts/dealerListContext';
import { UserLocationContext } from '../ReactContexts/userLocationContext';
import { EquipmentListContext } from '../ReactContexts/equipmentListContext';

interface IUserCoordinates {
  isEquipment?: boolean;
}

export function UserCoordinates(props: IUserCoordinates) {
  const { userLocation, setUserLocation } =
    React.useContext(UserLocationContext);

  React.useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      position => {
        setUserLocation({ location: position });
      },
      () => {
        setUserLocation({ location: {} as GeolocationPosition });
      },
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, [setUserLocation]);

  const { setDealerListData } = React.useContext(DealerListContext);
  const { setEquipmentListData } = React.useContext(EquipmentListContext);

  const buttonClicked = () => {
    if (props.isEquipment) {
      setEquipmentListData(oldValue => ({
        ...oldValue,
        panTo: {
          position: {
            lng: userLocation?.location?.coords?.longitude,
            lat: userLocation?.location?.coords?.latitude,
          },
          zoom: 13,
        },
      }));
      return;
    }
    setDealerListData(oldValue => ({
      ...oldValue,
      panTo: {
        position: {
          lng: userLocation?.location?.coords?.longitude,
          lat: userLocation?.location?.coords?.latitude,
        },
        zoom: 13,
      },
    }));
  };

  if (!userLocation?.location?.coords) {
    return <></>;
  }

  return (
    <>
      <MarkerF
        key="userCoordinate"
        position={{
          lng: userLocation?.location?.coords?.longitude,
          lat: userLocation?.location?.coords?.latitude,
        }}
        icon={{
          url: LocationImage,
          scale: 1,
          scaledSize: new window.google.maps.Size(50, 50, 'px', 'px'),
          anchor: new window.google.maps.Point(25, 25), // Center of the icon
        }}
      />
      <img
        className="userCoordinates-button"
        onClick={buttonClicked}
        src={MapLocalize}
      />
    </>
  );
}
