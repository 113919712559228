import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ChevronRightIcon from 'assets/images/chevron_right.svg';
import LegalListingIcon from 'assets/images/ic_listing_legal.svg';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';

export function LegalTermsPage() {
  const [isCanada, setIsCanada] = React.useState<boolean>(false);
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { t, i18n } = useTranslation();

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  React.useEffect(() => {
    window.navigator.language.split('-')[1]?.toLocaleUpperCase() === 'CA' &&
      setIsCanada(true);
  }, []);

  const handleTermsClick = () => {
    isCanada
      ? i18n.language === 'fr'
        ? window.open('https://kubota.ca/fr/terms-conditions', '_blank')
        : window.open('https://kubota.ca/terms-conditions', '_blank')
      : window.open(
          'https://www.kubotausa.com/terms-of-use/kusa-terms-of-use',
          '_blank',
        );
  };

  const handlePolicyClick = () => {
    isCanada
      ? i18n.language === 'fr'
        ? window.open('https://kubota.ca/fr/privacy-policy', '_blank')
        : window.open('https://kubota.ca/privacy-policy', '_blank')
      : window.open(
          'https://www.kubotausa.com/privacy-policy/kusa-online-privacy-policy',
          '_blank',
        );
  };

  return (
    <div className="box-content no-left-right-paddings legal-terms box-content-full-height">
      <h2 className="legal-terms-headers">
        {t('legal_terms_preference_title')}
      </h2>
      <div className="arrow-links arrow-links-no-border">
        <div className="arrow-links_item" onClick={() => handleTermsClick()}>
          <img
            className="arrow-links_icon item-left"
            src={LegalListingIcon}
          ></img>
          <span className="arrow-links_title">{t('terms_of_use')}</span>
          <img
            className="arrow-links_icon arrow-links_icon-right"
            src={ChevronRightIcon}
          />
        </div>
        <div className="arrow-links_item" onClick={() => handlePolicyClick()}>
          <img
            className="arrow-links_icon item-left"
            src={LegalListingIcon}
          ></img>
          <span className="arrow-links_title">{t('privacy_policy')}</span>
          <img
            className="arrow-links_icon arrow-links_icon-right"
            src={ChevronRightIcon}
          />
        </div>
      </div>
    </div>
  );
}
