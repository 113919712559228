import { ajax } from 'ajax/ajax';
import { getProcessedMaintenanceSchedule } from 'app/pages/TrackMaintenencePage/maintenanceUtils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IMaintenanceSchedule } from 'types/IMaintenanceSchedule';
import { ArrowLinkComponent } from './ArrowLinkComponent';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ic_nav_back as BackIcon } from 'app/widgets/SVGs';
import { MaintenanceHistoryContext } from 'app/components/ReactContexts/maintenanceHistoryContext';
import { GlobalErrorContext } from 'app/components/ReactContexts/globalErrorContext';

export function MaintenanceSchedules(props) {
  const { t } = useTranslation();
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);
  React.useEffect(() => {
    let index: number = setClassNames('layout-grey');

    return () => removeClassNames(index);
  }, []);

  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { maintenanceHistoryData, setMaintenanceHistoryData } =
    React.useContext(MaintenanceHistoryContext);

  const [maintenanceSchedules, setMaintenanceSchedules] =
    React.useState<IMaintenanceSchedule[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isTrackMaintenance = location.pathname.includes('/trackMaintenance');
  const model = equipmentListData.selectedEquipment
    ? equipmentListData.selectedEquipment.model
    : params.model;

  React.useEffect(() => {
    (!maintenanceHistoryData.maintenanceIntervals ||
      maintenanceHistoryData.selectedModel != model) &&
      (async () => {
        setIsLoading(true);
        await ajax
          .get(`${ajax.appBaseUrl}/api/maintenanceSchedule/${model}`, {
            headers: {
              version: '2022_R03',
            },
          })
          .then(response => {
            setMaintenanceHistoryData({
              ...maintenanceHistoryData,
              maintenanceIntervals: getProcessedMaintenanceSchedule(
                response.data,
                isTrackMaintenance,
              ),
              selectedModel: model,
            });
          })
          .catch(err => addErrorModal())
          .finally(() => {
            setIsLoading(false);
          });
      })();
  }, []);

  React.useEffect(() => {
    if (maintenanceHistoryData && maintenanceHistoryData.maintenanceIntervals) {
      const sortedIntervals = maintenanceHistoryData.maintenanceIntervals.sort(
        (a, b) =>
          a.sortOrder - b.sortOrder || a.intervalValue - b.intervalValue,
      );
      setMaintenanceSchedules(sortedIntervals);
    }
  }, [maintenanceHistoryData.maintenanceIntervals]);

  return (
    <div className="layout-info-panel maintanance-schedule-panel">
      <div className="layout-info-panel_container">
        <span
          className="back-button back-button-on-layout fixed-on-desktop"
          onClick={() => navigate(-1)}
        >
          <BackIcon />
        </span>
        <div className="box-content">
          <div className="content-container">
            <div className="info-model ellipsis">
              {equipmentListData.selectedEquipment?.nickName || model}
            </div>
            <div className="layout-info-panel-header">
              <h1>{t('maintenance_schedules')}</h1>
            </div>
            <div className="info-panel-list-items mentainence-list scroll-area">
              {isLoading ? (
                <>
                  <div className="loading">
                    <div className="loading_text">{t('loading...')}</div>
                    <div className="loading_icon">
                      <div className="css-icon"></div>
                    </div>
                  </div>
                </>
              ) : (
                maintenanceSchedules?.map((item, index) => (
                  <ArrowLinkComponent
                    key={index}
                    index={index}
                    scheduleItem={item}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
