import { Button } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IErrorModalProps {
  title: string;
  text: string;
  onClose: () => void;
}

export function ErrorModal(props: IErrorModalProps) {
  const { t } = useTranslation();

  return (
    <div className="remove-item-dialog-content">
      <h3>{props.title}</h3>
      <p>{props.text}</p>
      <div className="row buttons-wrapper">
        <div className="col-xxs-12">
          <Button onClick={props.onClose} themeColor="primary">
            {t('ok')}
          </Button>
        </div>
      </div>
    </div>
  );
}
