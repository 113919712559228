import { Card, CardBody } from '@progress/kendo-react-layout';
import { isCanada } from 'app/common/websiteCountry';
import { getHaversineDistance } from 'app/components/HerversineDistance/HaversineDistance';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { UserLocationContext } from 'app/components/ReactContexts/userLocationContext';
import {
  getCanadaFormateDate,
  getUSFormateDate,
} from 'app/pages/TrackMaintenencePage/maintenanceUtils';
import EvEquipmentIcon from 'assets/images/ic_equipment_ev.svg';
import PinError from 'assets/images/ic_pin_error.svg';
import { getLocale } from 'locales/utils';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { LocationUnavailableGeofenceDialog } from './LocationUnavailableGeofenceDialog';
import { CustomPoint, checkInside } from './utils';
import { getDefaultThumbnail } from '../utils';

interface IGeofenceEquipmentCard {
  index: number;
  equipment: IEquipmentEntry;
  measurementUnit: 'US' | 'METRIC';
}

export function GeofenceEquipmentCard(props: IGeofenceEquipmentCard) {
  const { setEquipmentListData: setData, equipmentListData } =
    React.useContext(EquipmentListContext);

  const cardCoordinatesRef = React.useRef<{
    screenX: number;
    screenY: number;
    hasMoved: boolean;
  }>();

  const { userLocation } = React.useContext(UserLocationContext);
  const { removeSpecificClass } = React.useContext(LayoutContext);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [showLocationUnavailableDialog, setShowLocationUnavailableDialog] =
    React.useState<boolean>(false);
  const { t } = useTranslation();
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowLocationUnavailableDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const spanRef = React.useRef<HTMLSpanElement | null>();

  React.useEffect(() => {
    if (spanRef.current) {
      if (equipmentListData.selectedEquipment?.id === props.equipment.id) {
        if (
          spanRef.current.getBoundingClientRect().bottom > window.innerHeight
        ) {
          spanRef.current.scrollIntoView(false);
        }

        if (spanRef.current.getBoundingClientRect().top < 0) {
          spanRef.current.scrollIntoView();
        }
        if (!props.equipment.telematics?.location) {
          setShowLocationUnavailableDialog(true);
        }
      }
    }
  }, [equipmentListData.selectedEquipment?.id]);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const markerPosition = {
    lat: props.equipment.telematics?.location?.latitude || -1,
    lng: props.equipment.telematics?.location?.longitude || -1,
  };

  const isEV = !!props.equipment.telematics?.electric;

  let insideGeofence = false;
  equipmentListData.geofenceList?.map(geofence => {
    let polygon = geofence.points.map(
      gfp => new CustomPoint(gfp.latitude, gfp.longitude),
    );
    let p = new CustomPoint(markerPosition.lat, markerPosition.lng);
    let n = polygon.length;

    if (checkInside(polygon, n, p)) {
      insideGeofence = true;
    }
    return geofence;
  });

  if (equipmentListData.geofenceList?.length === 0) {
    insideGeofence = true;
  }

  const locale = getLocale();
  const getDate = () => {
    const locationTime = props.equipment?.telematics?.locationTime || '';
    return isCanada || locale === 'fr-CA' || locale === 'en-CA'
      ? getCanadaFormateDate(locationTime, 'date_time', locale)
      : getUSFormateDate(locationTime, locale);
  };

  return (
    <>
      {showLocationUnavailableDialog && (
        <LocationUnavailableGeofenceDialog
          close={() => {
            setShowLocationUnavailableDialog(false);
          }}
        />
      )}
      <span
        ref={ref => {
          spanRef.current = ref;
        }}
      >
        <Card
          className={`equipment-list-card ${
            props.equipment.id === equipmentListData.selectedEquipment?.id
              ? 'equipment-list-card-selected'
              : ''
          }`}
          onMouseDown={e => {
            cardCoordinatesRef.current = {
              screenX: e.screenX,
              screenY: e.screenY,
              hasMoved: false,
            };
          }}
          onMouseMove={e => {
            if (!cardCoordinatesRef.current) {
              return;
            }
            if (
              Math.abs(e.screenX - cardCoordinatesRef.current.screenX) +
                Math.abs(e.screenY - cardCoordinatesRef.current.screenY) >
              10
            ) {
              cardCoordinatesRef.current = {
                hasMoved: true,
                screenX: -100,
                screenY: -100,
              };
            }
          }}
          onMouseUp={() => {
            if (!cardCoordinatesRef.current?.hasMoved) {
              if (width <= 1024) {
                removeSpecificClass('geofences-menu-extended');
              }
              let panLat = props.equipment.telematics?.location
                ? props.equipment.telematics?.location.latitude
                : userLocation?.location?.coords?.latitude || 32.97932;
              let panLong = props.equipment.telematics?.location
                ? props.equipment.telematics?.location.longitude
                : userLocation?.location?.coords?.longitude || -97.029314;

              setData(oldData => ({
                ...oldData,
                selectedEquipment: props.equipment,
                panTo: {
                  position: {
                    lat: panLat,
                    lng: panLong,
                  },
                  zoom: 14,
                },
              }));
              if (!props.equipment.telematics?.location) {
                setShowLocationUnavailableDialog(true);
              }
            }
          }}
        >
          <CardBody>
            <div className="row-3">
              <div className="col-xxs-3 geofences-list-icon-image-container">
                <div
                  className={`geofences-list-icon-circle ${
                    !insideGeofence ? 'with-red-border' : ''
                  }`}
                >
                  <img
                    src={
                      props.equipment.modelIconUrl ||
                      getDefaultThumbnail(
                        props.equipment.type,
                        props.equipment.subcategory,
                      )
                    }
                    className="geofences-list-icon-image geofences-list-icon-image-modelIcon"
                  />
                  {isEV ? (
                    <img
                      src={EvEquipmentIcon}
                      className="geofences-list-icon-image geofences-list-icon-image-evPin"
                    />
                  ) : null}
                  {!_.isEmpty(props.equipment.telematics?.faultCodes) && (
                    <img
                      src={PinError}
                      className="geofences-list-icon-image geofences-list-icon-image-redPin"
                    />
                  )}
                </div>
              </div>
              <div className="col-xxs-6 geofences-list-text-container">
                <div
                  className="equipment-list-nickname geofences-list-title"
                  title={props.equipment.nickName || props.equipment.model}
                >
                  {props.equipment.nickName || props.equipment.model}
                </div>
                {props.equipment?.telematics?.location ? (
                  <div className="geo-time">{getDate()}</div>
                ) : (
                  <div className="geo-time">{t('location_unavailable1')}</div>
                )}
              </div>
              <div className="col-xxs-3 geofences-list-distance-container">
                {userLocation?.location?.coords &&
                  props.equipment.telematics?.location?.latitude &&
                  props.equipment.telematics?.location?.longitude && (
                    <span>
                      {props.measurementUnit === 'US' ? (
                        <>
                          {Math.floor(
                            0.000621371 *
                              getHaversineDistance(
                                {
                                  latitude:
                                    userLocation?.location?.coords.latitude,
                                  longitude:
                                    userLocation?.location?.coords.longitude,
                                },
                                props.equipment.telematics?.location,
                              ) *
                              100,
                          ) / 100}{' '}
                          mi
                        </>
                      ) : (
                        <>
                          {Math.floor(
                            (getHaversineDistance(
                              {
                                latitude:
                                  userLocation?.location?.coords.latitude,
                                longitude:
                                  userLocation?.location?.coords.longitude,
                              },
                              props.equipment.telematics?.location,
                            ) /
                              1000) *
                              100,
                          ) / 100}{' '}
                          km
                        </>
                      )}
                    </span>
                  )}
              </div>
            </div>
          </CardBody>
        </Card>
      </span>
    </>
  );
}
