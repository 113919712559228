import AttachmentThumbnail from 'assets/images/ph_category_attachment_thumbnail.png';
import ConstructionCategoryThumbnail from 'assets/images/ph_category_construction_thumbnail.png';
import MowerCategoryThumbnail from 'assets/images/ph_category_mower_thumbnail.png';
import TractorCategoryThumbnail from 'assets/images/ph_category_tractor_thumbnail.png';
import UtvCategoryThumbnail from 'assets/images/ph_category_utv_thumbnail.png';

export function getDefaultThumbnail(
  type: string = 'machine',
  category?: string,
) {
  if (type === 'attachment') {
    return AttachmentThumbnail;
  }

  switch (category) {
    case 'Mowers':
      return MowerCategoryThumbnail;
    case 'Utility Vehicles':
      return UtvCategoryThumbnail;
    case 'Tractors':
      return TractorCategoryThumbnail;
    case 'Construction':
    default:
      return ConstructionCategoryThumbnail;
  }
}
