import { NotificationGroup } from '@progress/kendo-react-notification';
import { LargeBanner } from 'app/components/LargeBanner';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import BannerEmail from 'assets/images/ill_banner_email.svg';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const WelcomePopUp = () => {
  const anchor = React.useRef(null);

  const { t } = useTranslation();
  const { notifications, addNotification } =
    React.useContext(NotificationContext);

  React.useEffect(() => {
    const username = localStorage.getItem('kubota_user_name') || '';
    const lastPopupUsername = localStorage.getItem(
      'kubota_last_popup_username',
    );

    if (username !== lastPopupUsername) {
      addNotification(
        <div className="alert-container">
          <span className="alert-icon">
            <img alt="Notification Email" src={BannerEmail}></img>
          </span>
          <span className="alert-texts">
            <span>
              <b>{t('thanks_for_signing_up')}</b>
            </span>
            <span>{t('please_check_your_inbox_pop')}</span>
          </span>
        </div>,
        6000,
      );
      localStorage.setItem('kubota_last_popup_username', username);
    }
  }, []);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        localStorage.setItem('justLoggedOn', 'false');
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <>
      {localStorage.getItem('justLoggedOn') === 'true' && <LargeBanner />}
      <NotificationGroup className="alert position" ref={anchor}>
        {notifications?.map(notification => notification.element)}
      </NotificationGroup>
    </>
  );
};
