import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DealerListContext,
  DealerTabs,
} from 'app/components/ReactContexts/dealerListContext';
import { DealerCard } from './DealerCard';

import EmptyFavoritesPicture from 'assets/images/ill_empty_favorites.svg';
import EmptyDealersPicture from 'assets/images/ill_empty_dealers.svg';
import SearchIcon from 'assets/images/ic_search.svg';
import { librariesList } from 'app/common/librariesList';
import { useLoadScript } from '@react-google-maps/api';
import { LocationSearch } from './LocationSearch';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import _ from 'lodash';
import { useMeassurementUnit } from 'app/hooks/useMeasurementUnit';
import ClickOutside from 'app/widgets/ClickOutside';
import { getRegion } from 'locales/utils';

interface IDealerList {}

export function DealerList(props: IDealerList) {
  const { dealerListData, setDealerListData } =
    React.useContext(DealerListContext);

  const { t } = useTranslation();

  const [lastSerch, setLastSerch] = React.useState(
    [] as google.maps.places.AutocompletePrediction[],
  );
  const [closeSerchMessage, setCloseSerchMessage] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const scrolableRef = React.useRef(null as null | HTMLDivElement);
  const posRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });
  const { classNames, setClassNames, removeClassNames } =
    React.useContext(LayoutContext);
  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    // Scroll the element
    scrolableRef.current!.scrollTop = posRef.current.top - dy;
    scrolableRef.current!.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    scrolableRef.current!.style.removeProperty('user-select');
  };

  const mouseDownHandler = function (e) {
    if (!isDialogOpen) {
      if (!scrolableRef.current) {
        return;
      }
      posRef.current = {
        // The current scroll
        left: scrolableRef.current!.scrollLeft,
        top: scrolableRef.current!.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      scrolableRef.current!.style.userSelect = 'none';

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY as string,
    libraries: librariesList,
  });

  const [predictions, setPredictions] = React.useState(
    [] as google.maps.places.AutocompletePrediction[],
  );

  const [isSearching, setIsSearching] = React.useState(false);

  const inputChanged = e => {
    setSearchValue(e.target.value);
    const autoCompleteService =
      new window.google.maps.places.AutocompleteService();
    if (e.target.value === '') {
      setPredictions([]);
      return;
    }
    setIsSearching(true);
    autoCompleteService.getPlacePredictions(
      {
        input: e.target.value,
        types: ['(regions)'],
        componentRestrictions: { country: [getRegion()] },
      },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setIsSearching(false);
          setPredictions(predictions || []);
        } else {
          setIsSearching(false);
          setPredictions([]);
        }
      },
    );
  };

  React.useEffect(() => {
    let index: number | undefined = undefined;
    if (!_.some(classNames, cls => cls.class === 'layout-dealers')) {
      index = setClassNames('layout-dealers');
    }

    return () => {
      if (index || index === 0) {
        removeClassNames(index);
      }
    };
  }, []);

  React.useEffect(() => {
    setCloseSerchMessage(false);
    if (
      lastSerch.length > 0 &&
      searchValue != lastSerch[0].structured_formatting.main_text
    ) {
      setLastSerch([]);
    }
  }, [searchValue]);

  const measurementUnit = useMeassurementUnit();

  return (
    <div className="equipment-list dealers-list relative">
      <div className="equipment-list-top-bar dealers-top-bar">
        <span className="search-with-options">
          <div className="site-search open">
            <span className="search-icon">
              <img alt="Search" src={SearchIcon}></img>
            </span>
            <div className="search-input">
              {isLoaded && (
                <>
                  <input
                    value={searchValue}
                    onChange={inputChanged}
                    type="text"
                    id="autocomplete"
                    placeholder={t('dealers_search_hint')}
                  />
                  {searchValue && (
                    <span
                      className="search-icon-close"
                      onClick={() => {
                        setPredictions([]);
                        setSearchValue('');
                      }}
                    >
                      <BlackCloseIcon />
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="search-options">
              <div className="k-card k-card-vertical">
                <div className="k-card-body">
                  {!isSearching &&
                    !closeSerchMessage &&
                    searchValue?.length > 0 &&
                    !(predictions?.length > 0) &&
                    !(lastSerch.length > 0) && (
                      <ClickOutside
                        onClickOutside={() => setCloseSerchMessage(true)}
                      >
                        <div className="not-found-message">
                          {t('no_result_for').replace('{{s}}', searchValue)}
                        </div>
                      </ClickOutside>
                    )}
                  {isSearching &&
                    searchValue?.length > 0 &&
                    !(predictions?.length > 0) && (
                      <div className="not-found-message">
                        {t('search_for').replace('{{s}}', searchValue)}
                      </div>
                    )}
                  {predictions &&
                    predictions.map((prediction, index) => {
                      return (
                        <LocationSearch
                          key={`predictionPlaceSearch${index}`}
                          prediction={prediction}
                          placeId={prediction.place_id}
                          callback={() => {
                            setLastSerch([prediction]);
                            setPredictions([]);
                            setSearchValue(
                              prediction.structured_formatting.main_text,
                            );
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </span>

        <div className="row-2 tabs">
          <div
            className={`col-xxs-6 dealers-list-tab ${
              dealerListData.dealerTab !== DealerTabs.Favorites
                ? 'dealers-list-tab-selected'
                : ''
            }`}
            onClick={() => {
              setDealerListData(oldState => ({
                ...oldState,
                dealerTab: DealerTabs.Locations,
              }));
            }}
          >
            {t('locator_tab')}
          </div>
          <div
            className={`col-xxs-6 dealers-list-tab ${
              dealerListData.dealerTab === DealerTabs.Favorites
                ? 'dealers-list-tab-selected'
                : ''
            }`}
            onClick={() => {
              setDealerListData(oldState => ({
                ...oldState,
                dealerTab: DealerTabs.Favorites,
              }));
            }}
          >
            {t('my_dealers_tab')}
          </div>
        </div>
      </div>
      <div
        className="equipment-list-scroll-items"
        ref={scrolableRef}
        onMouseDown={dealerListData.dealerList && mouseDownHandler}
      >
        {!dealerListData.listIsLoading &&
          dealerListData.dealerTab === DealerTabs.Favorites &&
          dealerListData.favoriteDealers?.length === 0 && (
            <>
              <div className="dealers-list-empty-wrapper">
                <img
                  className="dealers-list-empty-image"
                  src={EmptyFavoritesPicture}
                />
                <h4>{t('no_favorites_yet')}</h4>
                <h5>{t('find_your_closest_dealer')}</h5>
              </div>
            </>
          )}

        {!dealerListData.listIsLoading &&
          dealerListData.dealerTab !== DealerTabs.Favorites &&
          dealerListData.dealerList?.length === 0 && (
            <>
              <div className="dealers-list-empty-wrapper">
                <img
                  className="dealers-list-empty-image"
                  src={EmptyDealersPicture}
                />
                <h4>{t('no_dealers_near')}</h4>
                <h5>{t('there_are_no_dealerships')}</h5>
              </div>
            </>
          )}

        {dealerListData.listIsLoading &&
        (dealerListData.dealerTab !== DealerTabs.Favorites ||
          (dealerListData.dealerTab === DealerTabs.Favorites &&
            !((dealerListData.favoriteDealers?.length || -1) >= 0))) ? (
          <div className="dealers-list-loading">
            <div className="loading">
              <div className="loading_text">{t('loading_dealers')}</div>
              <div className="loading_icon">
                <div className="css-icon"></div>
              </div>
            </div>
          </div>
        ) : (
          (dealerListData.dealerTab === DealerTabs.Favorites
            ? dealerListData.favoriteDealers
            : dealerListData.dealerList
          )?.map(dealer => {
            return (
              <DealerCard
                dealer={dealer}
                measurementUnit={measurementUnit}
                key={`dealerCard${dealer.dealerNumber}`}
                setIsDialogOpen={setIsDialogOpen}
              />
            );
          })
        )}
      </div>
    </div>
  );
}
