import * as React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    const element = document.getElementsByClassName('layout_container')[0];
    element && element.scroll(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
